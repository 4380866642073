<template>
  <div>
    <v-card outlined>
      <v-container fluid>
        <v-row align="center">
          <v-col>
            <v-img
              :src="getLogo"
              max-height="150"
              max-width="150"
              alt=""
              @load="loaded"
            />
          </v-col>
          <v-col class="text-center">
            <h1>{{ getOfficeName }}</h1>
          </v-col>
          <v-col class="text-right">
            <!-- <h4>{{ $store.state.userData.office.numberPhone }}</h4> -->
            <!-- <h4>{{ getNumber }}</h4> -->
            <h4>0780 808 0586</h4>
            <h4>0782 210 3349</h4>
            <h4>0771 322 7788</h4>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <!-- <div
      class="d-flex justify-space-between box grey lighten-1 pa-3 rounded my-1"
    >
      <div> -->
    <!-- <h1>{{ this.$store.state.userData.office.name }}</h1> -->

    <!-- <div class="d-flex align-center justify-space-around">
          <v-icon>mdi-phone</v-icon> 
          {{
            this.$store.state.userData.office.numberPhone
          }}

        </div> -->

    <!-- </div> -->
    <!-- <pre dir="ltr">
        {{$store.state.userData.office}}
        {{$service.url}}
      </pre> -->
    <!-- :src="$service.url + $store.state.userData.office.logoUrl" -->

    <!-- </div> -->
    <div class="d-flex justify-center">
      <div class="box">
        <div
          class="d-flex flex-column justify-space-around align-content-start pa-3"
        >
          <div class="d-flex justify-space-between align-center">
            <div class="d-flex flex-column">
              <!-- <h3>Created By: {{ order.userCreated.fullName }}</h3> -->
              <h3 class="mt-2">
                {{ type.replace("Orders", "") }} Name : {{ name }}
              </h3>
              <h3 class="mt-2">Region : {{ regionName }}</h3>
              <h3 class="mt-2">
                Order payment : {{ order.isCash ? "Cash" : "Delayed" }}
              </h3>
              <h3>
                Order created date:
                {{ $service.formatDate(order.created, false) }}
              </h3>
              <h3>Order No: {{ order.orderNO }}</h3>
              <!-- <h3 class="mt-2">Order Note : {{ order.note }}</h3> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <table class="mt-1">
      <thead>
        <tr>
          <th>Products</th>
          <th>Batch NO.</th>
          <th>Dose</th>
          <th>Dosage Form</th>
          <th style="width: 6%">Qty</th>
          <th style="width: 6%">Bonus</th>
          <th style="width: 6%">U IQD</th>
          <th style="width: 6%">Discount</th>
          <th style="width: 6%">Price</th>
          <!-- <th>Transit to</th> -->
        </tr>
      </thead>
      <tbody>
        <template v-for="(product, i) in order.products">
          <tr v-if="product.approved" :key="i">
            <td>{{ product.product.tradeName }}</td>
            <td>
              <div v-if="order.status == 7">
                <v-chip
                  class="mx-2"
                  label
                  v-for="batch in product.batches"
                  :key="batch.id"
                >
                  {{ batch.batchNo }}
                </v-chip>
              </div>
            </td>
            <td>
              {{ product.product.dose }}
            </td>
            <td>
              {{ product.product.productDosageFormName }}
            </td>
            <td>{{ product.qty }}</td>
            <td>{{ product.bonus }}</td>
            <td>
              {{ $service.formatCurrency(product.price) }}
            </td>
            <td>0</td>
            <td>
              {{ $service.formatCurrency(product.price * product.qty) }}
            </td>
          </tr>
        </template>
      </tbody>
    </table>
    <div class="mb-10">
      <div class="d-flex justify-space-between">
        <div class="d-felx flex-column align-center">
          <div class="d-flex align-center">
            <p>Total Amounts:</p>
            <p class="grey lighten-1 pa-2 mx-2 rounded">
              {{ $service.formatCurrency(totalPrice) }}
            </p>
            <p>IQD</p>
          </div>
          <div class="d-flex align-center">
            <p>Total Dis.:</p>
            <p class="grey lighten-1 pa-2 mx-2 rounded">0.00</p>
            <p>IQD</p>
          </div>
          <div class="d-flex align-center">
            <p>Net Amounts:</p>
            <p class="grey lighten-1 pa-2 mx-2 rounded">
              {{ $service.formatCurrency(totalPrice) }}
            </p>
            <p>IQD</p>
          </div>
        </div>
        <p class="text-decoration-underline">Signature of receipt</p>
      </div>
    </div>

    <!-- <footer class="box pa-2 d-flex justify-space-between mt-10">
      <p style="font-size: 16px">0773232323: التحصيل والمحاسبة</p>
      <p style="font-size: 16px">
        0773232323 - 0773232323 - 0773232323: الادارة والمبيعات
      </p>
    </footer> -->
  </div>
</template>

<script>
export default {
  name: "Order",
  data() {
    return {
      loading: true,
      order: {
        userCreated: {
          fullName: "",
        },
        phamarcy: {
          name: "",
        },
      },
      type: "PharmacyOrders",
      orderId: this.$route.params.id,
      approvedProducts: [],
      headers: [
        {
          text: "Item",
          value: "productTradeName",
        },
        {
          text: "Batch No.",
          value: "bno",
        },
        {
          text: "Exp Date",
          value: "expDate",
        },
        {
          text: "QTY",
          value: "qty",
        },
        {
          text: "Status",
          value: "approved",
        },
        {
          text: this.$t('actions'),
          value: "action",
        },
      ],
      office: {},
      done: [],
      confirmReject: false,
      isLoaded: false,
    };
  },
  methods: {
    getInvoice() {
      this.loading = true;
      this.$http.get(`/${this.type}/` + this.orderId).then((response) => {
        this.order = response.data.result;
        if (this.$route.params.product != "all") {
          this.order.products = this.order.products.filter(
            (product) => product.id == this.$route.params.product
          );
        }
        // else {
        //   this.order.products = this.order.products.filter(
        //     (product) => product.approved == true
        //   );

        // }

        this.loading = false;
      });
    },
    print() {
      window.print();
    },
    loaded() {
      this.isLoaded = true;
    },
  },
  computed: {
    getNumber() {
      return (
        JSON.parse(localStorage.getItem("userInfo")).userData.phoneNumber ||
        false
      );
    },
    getOfficeName() {
      return JSON.parse(localStorage.getItem("userInfo")).office.name || false;
    },
    getLogo() {
      return (
        JSON.parse(localStorage.getItem("userInfo")).office.logoUrl || false
      );
    },
    totalDiscount() {
      let discount = 0;
      this.order.products.forEach((item) => {
        if (item.approved == true) {
          discount += item.discount;
        }
      });
      return discount;
    },
    totalPrice() {
      let total = 0;
      this.order.products.forEach((item) => {
        if (item.approved == true) {
          total += item.price * item.qty;
        }
      });
      return total;
    },
    totalPriceAfterDiscount() {
      let total = 0;
      this.order.products.forEach((product) => {
        if (product.approved == true) {
          total += product.price * product.qty;
        }
      });
      return total;
    },
    typeText() {
      return this.type.replace("Orders", "");
    },
    name() {
      if (this.type == "PharmacyOrders") {
        if (this.order.pharmacy !== undefined) {
          return this.order.pharmacy.fullName;
        }
        return "test";
      } else if (this.type == "RepositoryOrders") {
        if (this.order.repository !== undefined) {
          return this.order.repository.fullName;
        }
        return "";
      } else if (this.type == "DoctorOrders") {
        if (this.order.doctor !== undefined) {
          return this.order.doctor.fullName;
        }
        return "";
      }
      return "";
    },
    regionName() {
      if (this.type == "PharmacyOrders") {
        return this.order.pharmacy.regionName;
      } else if (this.type == "RepositoryOrders") {
        return this.order.repository.regionName;
      }
      return this.order.doctor.regionName;
    },
    status() {
      if (this.type == "PharmacyOrders") {
        return this.order.pharmacy.status;
      } else if (this.type == "RepositoryOrders") {
        return this.order.repository.status;
      }
      return this.order.doctor.status;
    },
  },
  watch: {
    loading(val) {
      if (!val) {
        // this.order.products.filter(product => product.product.id == this.$route.params.product)
      }
    },
    $route: {
      handler() {
        this.type = this.$route.params.type;

        this.getInvoice();
      },
      immediate: true,
    },
  },
  updated() {
    if (this.isLoaded) {
      window.print();
    }
  },
  created() {
    if (this.orderId !== undefined) {
      this.loading = true;
      this.$http
        .get("/Offices/" + this.$store.state.userInfo.office.id)
        .then((res) => {
          this.office = res.data.result;
        })
        .finally(() => {
          this.getInvoice();
        });
    } else {
      // go back
      this.$router.push({
        name: "accounts-orders",
      });
    }
  },
};
</script>

<style>
@page {
  margin: 0;
}

header {
  display: none !important;
}

table {
  width: 100%;
  border-collapse: none;
  border-spacing: 0;
  margin-bottom: 20px;
  border: 1px solid #000000;
}

thead {
  background-color: #f5f5f5;
  color: #000000;
}

th {
  border: 1px solid #000000;
  padding: 2px;
}

td {
  color: black !important;
  border: 1px solid #000000;
  padding: 5px;
}

.box {
  width: 100%;
  border: 1px solid #000000;
  border-radius: 1px !important;
}

#app {
  padding: 0 !important;
  margin: 0 !important;
  color: black !important;
  background: white !important;
}

p {
  font-size: 20px;
  font-weight: bold !important;
}

.box {
  /* width: 100%; */
  /* border: 1px solid #000000;
  border-radius: 1px !important; */
}

footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;

  background: #f5f5f5;
}

@media print {
  body {
    -webkit-print-color-adjust: exact !important;
  }
}
</style>
